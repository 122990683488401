<template>
  <div class="container">
    <div class="video">
      <VuePlyr ref="plyr" :options="options">
        <video controls :src="src" crossorigin playsinline>
          <!-- <source size="720" :src="src" type="video/mp4" />
          <source size="1080" :src="src" type="video/mp4" /> -->
        </video>
      </VuePlyr>
    </div>
    <div class="title">{{ title }}</div>
    <div v-for="(timestamp, index) in timestamps" :key="index" class="timestamp">
      <p class="time" @click="goTo(timestamp.goTo)">{{ timestamp.time }}</p>
      <p class="description">{{ timestamp.description }}</p>
    </div>
  </div>
</template>

<script>
import VuePlyr from 'vue-plyr';

export default {
  name: 'how-to-use',
  components: {
    VuePlyr,
  },
  data() {
    return {
      startTime: 0,
      loaded: false,
      options: {
        controls: [
          'controls',
          'play-large', // The large play button in the center
          'rewind', // Rewind by the seek time (default 10 seconds)
          'play', // Play/pause playback
          'progress', // The progress bar and scrubber for playback and buffering
          'current-time', // The current time of playback
          'mute', // Toggle mute
          'volume', // Volume control
          'captions', // Toggle captions
          'settings', // Settings menu
          'fullscreen', // Toggle fullscreen
        ],
        settings: ['captions'],
        // autoplay: false,
        // clickToPlay: true,
        displayDuration: true,
        toggleInvert: false,
        volume: 1,
        keyboard: {
          focused: false,
          global: false,
        },
        fullscreen: {
          enabled: true,
          fallback: false,
          iosNative: false,
        },
        // listeners: {
        //   seek: this.preventSeek,
        // },
      },
    };
  },
  computed: {
    userRole() {
      return this.$store.state.User.userInfo.ILTRoleID;
    },
    videoURL() {
      if (this.userRole === 1) {
        return 'Participant URL';
      }
      return 'Admin, CD, Instructor URL';
    },
    title() {
      if (this.userRole === 1) {
        return 'Participants';
      }
      return 'Administrators, Content Developers, and Instructors';
    },
    src() {
      if (this.userRole === 1) {
        return '/static-media/VWCUP2020-0104_VW Digital Passport Participant_010721_1p_LLL.mp4';
      }
      return '/static-media/VWCUP2020-0103_VW Digital Passport Instructors_113020_LLL.mp4';
    },
    timestamps() {
      if (this.userRole === 1) {
        return [
          {
            time: '00:00 - 00:29',
            description: 'A brief overview of Digital Passport’s capabilities for Participant users',
            goTo: 0, // where to jump the video to
          },
          {
            time: '00:30 - 00:42',
            description: 'How to access Digital Passport through the Academy Suite',
            goTo: 30, // where to jump the video to
          },
          {
            time: '00:43 - 00:49',
            description: 'An overview of the Digital Passport Dashboard',
            goTo: 43, // where to jump the video to
          },
          {
            time: '00:50 - 02:02',
            description:
              'An overview of the Digital Passport “My Courses” page, filtering/sorting capabilities, grid/tile view, information on how the “recommended prerequisites” feature works, and how to launch/access a Virtual Classroom or Modular Training Toolkit',
            goTo: 50, // where to jump the video to
          },
          {
            time: '02:03 - 02:33',
            description:
              'How to access the Adobe Connect stream for a Virtual Classroom, how to navigate the content within the course itself, and how to use the note-taking feature',
            goTo: 123, // where to jump the video to
          },
          {
            time: '02:34 - 03:07',
            description: 'An overview of the Digital Passport Asset Library and filtering/sorting capabilities',
            goTo: 154, // where to jump the video to
          },
          {
            time: '03:08 - 03:19',
            description: 'A brief overview of why Digital Passport was created',
            goTo: 188, // where to jump the video to
          },
        ];
      }
      return [
        {
          time: '00:00 - 00:50',
          description: 'A brief overview of Digital Passport’s capabilities',
          goTo: 0, // where to jump the video to
        },
        {
          time: '00:50 - 01:52',
          description:
            'Specific information on Digital Passport, with more details on what users with Administrator permissions can do in the tool',
          goTo: 50, // where to jump the video to
        },
        {
          time: '01:53 - 01:57',
          description: 'More details on what users with Content Developer permissions can do in the tool',
          goTo: 113, // where to jump the video to
        },
        {
          time: '01:58 - 02:05',
          description: 'More details on what users with Instructor permissions can do in the tool',
          goTo: 118, // where to jump the video to
        },
        {
          time: '02:06 - 02:15',
          description: 'More details on what users with Participant permissions can do in the tool',
          goTo: 126, // where to jump the video to
        },
        {
          time: '02:16 - 03:25',
          description:
            'Types of courses (Virtual Classrooms and Modular Training Toolkits) Administrators and Content Developers can create',
          goTo: 136, // where to jump the video to
        },
        {
          time: '03:26 - 03:44',
          description: 'How Administrators and Content Developers can create a new course',
          goTo: 206, // where to jump the video to
        },
        {
          time: '03:45 - 05:10',
          description:
            'How Administrators and Content Developers can set up the boilerplate information for each course (what it’s called, who it’s for, who will be assigned as Instructor(s), etc.), and how the autosave feature works in the Digital Passport course builder',
          goTo: 225, // where to jump the video to
        },
        {
          time: '05:11 - 05:52',
          description:
            'How Administrators and Content Developers can set up modules, and how the delete/remove functionality works in Digital Passport courses',
          goTo: 311, // where to jump the video to
        },
        {
          time: '05:53 - 09:11',
          description:
            'How Administrators and Content Developers can set up sections, how the delete/remove functionality works in Digital Passport courses, how to use the drag-and-drop feature to reorder assets within sections, and how to set up the Participant guide and Instructor guides',
          goTo: 353, // where to jump the video to
        },
        {
          time: '09:12 - 09:37',
          description:
            'How the live preview function works for Administrators and Content Developers, and how they can see their course in Participant view, Instructor view, and across different devices',
          goTo: 552, // where to jump the video to
        },
        {
          time: '09:38 - 10:17',
          description: 'How published courses differ from courses in draft state, and how to publish a course',
          goTo: 578, // where to jump the video to
        },
        {
          time: '10:18 - 13:35',
          description:
            'An overview of the sidebar navigation for Administrator users (Dashboard, All Courses, Asset Library, Users, and Add User) and information on how the “recommended prerequisites” and “who it’s for” features work',
          goTo: 618, // where to jump the video to
        },
        {
          time: '13:36 - 13:44',
          description:
            'An overview of the sidebar navigation for Content Developer users (Dashboard, All Courses, Asset Library, and Users), which is very similar to that of the Administrator sidebar navigation',
          goTo: 816, // where to jump the video to
        },
        {
          time: '13:45 - 13:56',
          description:
            'An overview of the sidebar navigation for Instructor users (Dashboard, My Courses, and Asset Library), and why Instructors don’t see all published courses contained within Digital Passport',
          goTo: 825, // where to jump the video to
        },
        {
          time: '13:57 - 14:17',
          description:
            'An overview of the sidebar navigation for Participant users (Dashboard, My Courses, and Asset Library), and a brief overview of why Digital Passport was created',
          goTo: 837, // where to jump the video to
        },
      ];
    },
  },
  methods: {
    // jump to time in video
    goTo(time) {
      if (this.loaded) {
        this.$refs.plyr.player.currentTime = time;
      } else {
        this.startTime = time;
      }
      this.$refs.plyr.player.play();
      window.scrollTo({ top: 100, behavior: 'smooth' });
    },
  },
  mounted() {
    this.$refs.plyr.player.on('loadeddata', () => {
      if (this.startTime > 0) {
        this.$refs.plyr.player.currentTime = this.startTime;
      }
      this.loaded = true;
    });
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 80%;
  margin: 0 auto;
  @include md {
    width: auto;
    padding: 0 20px;
  }
  @include lg {
    padding: 0;
  }
  .video {
    margin-bottom: 25px;
  }
  .title {
    margin-bottom: 35px;
    font-family: $head-font-stack;
    font-weight: 300;
    font-size: 2rem;
  }
  .timestamp {
    margin-bottom: 25px;
    p {
      margin: 0;
      padding: 0;
    }
    .time {
      margin-bottom: 5px;
      font-family: $head-font-stack;
      font-weight: bold;
      font-size: 1.4rem;
      cursor: pointer;
      &:hover {
        color: $primary-light;
      }
    }
    .description {
      font-family: $text-font-stack;
      font-weight: 300;
      font-size: 1.4rem;
    }
  }
}
</style>
